"use client";

import { Group, Stack, Text } from "@mantine/core";
import { IconMapPin } from "@tabler/icons-react";
import Link from "next/link";
import CalendarDate from "~/components/CalendarDate";
import EventImage from "~/components/events/EventCoverImage";
import { RestrictedAgeBadge } from "~/components/events/RestrictedAgeBadge";
import { RouterOutputs } from "~/trpc/react";

type FeaturedEvent = RouterOutputs["events"]["featured"][0];

type Props = {
  event: FeaturedEvent;
};

export const FeaturedEventTile = ({ event }: Props) => (
  <Link
    href={`/events/${event.slug || event.id}`}
    data-testid="event-tile"
    className={`event-tile ${event.admin_status}-event-tile`}
  >
    <Stack style={{ gap: 10, cursor: "pointer" }}>
      <EventImage event={event} eventImage={event.images[0]} />

      <Group style={{ flexWrap: "nowrap" }} align="start">
        <CalendarDate date={new Date(event.start_time)} timeZone={event.time_zone} />

        <Stack style={{ gap: 0 }}>
          <Group style={{ flexWrap: "nowrap", justifyContent: "space-between" }}>
            <Text size="lg">{event.title}</Text>
            <RestrictedAgeBadge age={event.minimum_years_of_age} />
          </Group>

          <Text size="sm">{event.short_description}</Text>

          <Group style={{ flexWrap: "nowrap", gap: 2.5 }} mt={4}>
            <IconMapPin size={20} />
            <Text size="xs">{event.event_space.name}</Text>
          </Group>
        </Stack>
      </Group>
    </Stack>
  </Link>
);
