import { setLocale, setTranslations } from "react-i18nify";

export const initI18n = (locale = "en") => {
  setTranslations({
    en: {
      application: {
        name: "KiwiTicket",
        admin_name: "Admin",
        producer_name: "Producer",
        venues_name: "Venues",
      },
      actions: {
        okay: "Okay",
        cancel: "Cancel",
        save: "Save",
        update: "Update",
        saving: "Saving",
        loading: "Loading",
        searching: "Searching",
        search: "Search",
        close: "Close",
      },
      header: {
        dashboard: "Dashboard",
        users: "Users",
        events: "Events",
        tickets: "My Tickets",
        cart: "Cart",
        reporting: "Reporting",
        sell_tickets: "Sell Tickets",
        producer_portal: "Producer Portal",
      },
      footer: {
        privacy_header: "Privacy",
        privacy_notice: "Privacy Notice",
        terms_and_conditions: "Terms and Conditions",
        promoter_header: "Sell Tickets",
        fees: "Fees",
        contact: "Contact",
      },
      date: {
        long: "MMMM do, yyyy",
      },
      auth: {
        sign_in: "Sign In",
        sign_out: "Sign Out",
        login: "Login",
        google: "Google",
        continue_as_guest: "Continue as guest",
        already_have_an_account: "Already have an account?",
        dont_have_an_account: "Don't have an account?",
        register: "Register",
        forgot_your_password: "Forgot your password?",
        recover: "Recover",
        password_reset: {
          title: "Password reset",
          error: "Password reset failed",
          reset_failed_try_again: "Failed to reset your password at this time. Please try again.",
          check_email_for_instructions:
            "You will receive an email with instructions on how to reset your password shortly.",
          send_reset_instructions: "Send instructions",
        },
        verification: {
          email_sent: "An email has been sent to your address for verification.",
          check_instructions:
            "Please check your inbox and follow the instructions to complete setting up your account.",
          back_to_sign_in: "Back to sign in",
        },
        notifications: {
          titles: {
            unauthorised: "Login required",
            forbidden: "Access denied",
          },
          messages: {
            unauthorised: "You need to log in to access this page. Please log in and try again.",
            forbidden: "You don't have permission to access this page.",
          },
        },
        login_trouble: "Trouble logging in?",
        reset_password: "Reset your password",
        or_continue_with_email: "Or continue with email",
        or_continue_with_google: "Or continue with Google",
        agreement: {
          read_and_agree_to: "I have read and agree to the",
          customer_terms: "customer terms and conditions",
          producer_terms: "producer terms and conditions",
          privacy_notice: "privacy notice",
        },
        form: {
          email: {
            label: "Email",
            invalid: "Invalid email address",
            required: "Email is required",
          },
          password: {
            label: "Password",
            required: "Password is required",
            length: "Password needs to be at least 6 characters long",
          },
          passwordConfirmation: {
            label: "Password confirmation",
            required: "Password confirmation is required",
          },
          firstName: {
            label: "First name",
            required: "First name is required",
          },
          lastName: {
            label: "Last name",
            required: "Last name is required",
          },
        },
      },
      events: {
        see_all: "See all events",
        cancelled: "Cancelled",
        cancellation_disclaimer:
          "This event has been cancelled and tickets are no longer available.",
        tickets_remaining: "Hurry, only %{quantity} tickets left!",
        actions_menu: {
          title: "Actions",
          label: "Event actions",
        },
        event_status: {
          update_status: "Update status",
          new_status_title: "Update event status",
          new_status_label: "New status",
          new_status_placeholder: "Pick a status",
        },
        sales_suspension: {
          active: "Sales suspended",
          inactive: "Sales active",
        },
      },
      tickets: {
        redeem: "Redeem a complimentary ticket",
      },
      account: {
        guest_label: "Guest",
        account_label: "Account",
        purchase_history: "View purchase history",
        issues: {
          label: "Noticed any issues with the app or your account?",
          action: "Let us know at",
        },
        guest: {
          convert_to_account: "Create account",
          signed_in_as_guest: "Signed in as a guest",
        },
        sign_in: "Sign in",
        sign_out: "Sign out",
        delete: "Delete account & data",
      },
      cart: {
        event_referral: {
          how_did_you_hear: "How did you find out about this event?",
          facebook: "Facebook",
          instagram: "Instagram",
          twitter: "Twitter",
          website: "Website",
          friend: "Friends (word of mouth)",
          eventfinda: "Eventfinda",
          other: "Other",
          please_specify: "Please specify",
        },
        promotion: {
          applied: "Promotion applied",
          not_applicable: "Promotion not applicable",
          not_found: "Promotion not found",
        },
        errors: {
          quantity: {
            too_high: "There are only %{maxQuantity} tickets available",
            too_low: "Please enter a valid quantity",
          },
        },
        sold_out: "Sold out",
        available_from: "Available from %{readableDate}",
        no_longer_available: "No longer available",
        not_available: "Not available",
      },
      admission: {
        shared: {
          recalled:
            "This shared ticket has been recalled by the original holder, and is no longer valid for entry",
        },
      },
      fees: {
        bracket_start: "Bracket start",
        bracket_end: "Bracket end",
        add_bracket: "Add bracket",
        schedule_updated: "Fee schedule successfully updated",
        fee: "Fee",
        fee_type: "Fee type",
        percentage: "Percentage",
        fixed: "Fixed amount",
        reset_confirmation: "Reset",
        reset_custom_fees: "Reset custom fees",
        reset_event_fees_content:
          "This will reset the fees specific to the event. If the producer of the event has a custom schedule set, this will be used instead. Otherwise, the default fee schedule will apply.",
        reset_producer_fees_content:
          "This will reset the fees at the producer level to the standard fee schedule. Existing events with custom fees will not be affected.",
        producer: {
          standard_fees: "This producer is using the standard fees table",
          custom_fees: "This producer is using custom fees",
        },
        event: {
          standard_fees: "This event is using the standard fees table",
          producer_fees: "This event is using custom fees for the producer",
          custom_fees: "This event is using custom fees",
        },
        failed_to_load: "Failed to load fee schedule",
        table: {
          types: {
            fixed: "Fixed amount",
            percentage: "Percentage",
          },
        },
      },
      admin: {
        name: "Admin",
        menu: {
          dashboard: "Dashboard",
          events: "Events",
          event_payouts: "Payouts",
          producers: "Producers",
          users: "Users",
          reporting: "Reporting",
          integrations: "Integrations",
        },
        event: { name: "Event" },
        events: {
          name: "Events",
          description: "Manage events, approvals, and payouts",
          status: {
            all: "All events",
            draft: "Draft",
            unpublishedChanges: "Unpublished changes",
            submitted: "Submitted",
            resubmitted: "Re-submitted",
            live: "Published",
            pendingPayout: "Pending payout",
            complete: "Complete",
            past: "Complete",
            cancelled: "Cancelled",
            linkOnly: "Link-only",
            startsSoon: "Starts soon",
            activeNow: "Active now",
          },
          event_details: {
            name: "Event details",
          },
          fee_schedule: {
            name: "Fee schedule",
          },
          ticketing_information: {
            name: "Ticketing information",
          },
          transactions: {
            name: "Transactions",
          },
          promotions: {
            name: "Promotions",
          },
          coupons: {
            name: "Complimentary tickets",
          },
          promotions_and_coupons: {
            name: "Promotions and Comps",
          },
        },
        tickets: {
          state: {
            in_cart: "In cart",
            purchased: "Purchased",
            stale: "Stale",
            refunded: "Refunded",
            recalled: "Recalled",
            removed: "Removed",
          },
        },
        mailers: {
          name: "Mailers",
          tile_description: "Manually send / re-send mailers",
          preview: {
            send: "Send",
            success: "Sent successfully!",
          },
        },
        home: {
          name: "Home",
          description: "Head back to customer home page",
        },
        reporting: {
          name: "Reporting",
          description: "Dive deeper into data and insights",
          financial: {
            subtotal: "Subtotal",
            fees: "Fees",
            total: "Total",
            totals: "Totals",
          },
          event_financials_report: {
            name: "Event Financials",
            description: "Sales and profitability across events within a date range",
            exclude_events_without_sales: "Exclude events without sales",
          },
          producer_fees_report: {
            name: "Producer Fees",
            description: "Who are this month's Most Valuable Producers?",
          },
          settlement_report: {
            name: "Settlement Report",
            download: "",
            failed_to_download: "Failed to load settlement report data",
            total_payout: "Total payout",
            payouts_made_into_account: "Payouts made to %{accountName}",
            payout_account_not_set_up: "Payout account has not been set up",
          },
        },
        dashboard: {
          name: "Dashboard",
          description: "Track how the business is performing at a glance",
          trends: {
            same_as_previous: "Same as previous period",
            up_from_previous: "%{percentageDiff}% increase since previous period",
            down_from_previous: "%{percentageDiff}% decrease since previous period",
            inf_up_from_previous: "Increased since previous period",
            inf_down_from_previous: "Decreased since previous period",
          },
          stats: {
            events_run: {
              label: "Events run",
            },
            tickets_sold: {
              label: "Tickets sold",
            },
            user_signups: {
              label: "User signups",
            },
            gross_sales: {
              label: "Gross sales",
            },
            average_ticket_price: {
              label: "Average ticket price",
            },
            average_event_fees: {
              label: "Average event fees",
            },
            live_now: "Live now",
            last_week: "Last seven days",
            all_time: "All time",
          },
        },
        users: {
          name: "Name",
          given_name: "First name",
          family_name: "Last name",
          email: "Email",
          id: "Auth user ID",
          no_matches: "No matching users found",
          created_at: "Created at",
          inserted_at: "Created at",
          email_verification: {
            verified: "Verified",
            unverified: "Unverified",
            verify: "Verify",
          },
          impersonation: {
            name: "Impersonation",
            description: "Log in as the user, see what they see",
            impersonate: "Impersonate",
          },
          actions: {
            name: "Actions",
            description: "Actions you can take on this user",
            delete: "Delete account",
          },
          basic_details: {
            name: "Basic details",
            description: "General information about the user",
          },
          tickets: {
            name: "Tickets",
            description: "Tickets held on this user's account",
            no_results: "This user doesn't hold any tickets",
            event: "Event",
            ticket_type: "Type",
            state: "State",
            price: "Price",
            quantity: "Quantity",
            entries: "Entries",
            added_to_cart_at: "Added to cart at",
            purchased_at: "Purchased at",
          },
          transactions: {
            name: "Transactions",
            purchase_completed: "Purchase completed",
          },
        },
        producers: {
          name: "Producers",
          producer_name: "Name",
          email: "Contact email",
          description: "Manage custom fee schedules and approval settings for producers",
          no_matches: "No matching producers found",
          approvalSettings: {
            name: "Approval settings",
            trusted: {
              label: "Trusted producer",
              description: "Trusted producer — Submitted events are automatically published",
              optionLabel: "Submitted events are automatically published without needing approval",
            },
            untrusted: {
              label: "Untrusted producer",
              description: "Untrusted producer — Submitted events require approval",
              optionLabel:
                "Submitted events require approval before being published and going live",
            },
            success: {
              trusted: "Successfully marked producer as trusted",
              untrusted: "Successfully unmarked producer as trusted",
            },
          },
          fee_schedule: {
            name: "Fee schedule",
          },
        },
      },
      producer: {
        dashboard: {
          name: "Dashboard",
        },
        events: {
          name: "Events",
          sidebar: {
            ticketOverview: "Ticket overview",
            reporting: "Reporting",
            promotionsAndComps: "Promos & Comps",
            settings: "Settings",
          },
        },
        hubs: {
          name: "Hubs",
        },
        promotions: {
          name: "Promotions",
        },
        coupons: {
          name: "Coupons",
        },
        identity: {
          name: "Identity",
        },
        settings: {
          name: "Settings",
        },
      },
      venues: {
        dashboard: {
          name: "Dashboard",
        },
        settings: {
          name: "Settings",
        },
      },
      transactions: {
        status: {
          pending: "Pending",
          stale: "Stale",
          complete: "Complete",
          refunded: "Refunded",
          cancelled: "Cancelled",
          error: "Error",
        },
        badges: {
          tickets_added: "Tickets added",
          amount_refunded: "%{amount} refunded",
          fully_refunded: "Fully refunded",
        },
      },
      pdf: {
        settlementDocument: {
          payoutInformation: {
            header: {
              payoutSchedule: "Payout Schedule",
              amount: "Amount",
              paidToAccount: "Paid To Account",
              payoutAccountNotSet: "Account not set",
            },
          },
          settlementTable: {
            header: {
              ticketType: "Ticket Type",
              numberSold: "Number Sold",
              ticketValue: "Ticket Value",
              grossSales: "Gross Sales",
              feePerTicket: "Fee Per Ticket",
              totalFees: "Total Fees",
            },
            subtotals: "Subtotals",
            lessTotalFees: "Less total fees",
            totalPayout: "TOTAL PAYOUT",
            payoutAccountNotSet: "Account not set",
          },
          payoutScheduleStatement:
            "Please allow 3-7 business days for the payment to appear in your account.",
        },
      },
      api: {
        generic: {
          errors: {
            bad_request: "Bad request",
            not_found: "Not found",
            event_not_found: "Unable to find the event",
            unauthenticated: "Unauthenticated",
            forbidden: "Forbidden",
            upload_error: "Failed to upload",
          },
        },
        account: {
          create: {
            privacy_not_accepted: "The privacy policy must be accepted",
            terms_not_accepted: "The terms and conditions must be accepted",
            invalid_password: "Passwords must be at least 6 characters long",
            password_mismatch: "Passwords don't match",
            invalid_email: "Please enter a valid email address",
          },
        },
        auth: {
          sign_in: {
            invalid_password: "Invalid email and password combination.",
            not_found: "Invalid email and password combination.",
          },
        },
        events: {
          event: {
            delete: {
              errors: {
                has_sales:
                  "This event cannot be deleted as tickets have already been purchased. The event must be cancelled instead. Please contact the KiwiTicket team if you require any assistance.",
              },
            },
          },
        },
      },
    },
  });

  setLocale(locale);
};
