import { Event } from "./events";
/* eslint-disable no-unused-vars */
import { DefaultMantineColor } from "@mantine/core";
import {
  event_images,
  event_ticket_types,
  events,
  tickets,
  users,
  type event_spaces,
} from "@prisma/client";

export enum TicketState {
  InCart = "in_cart",
  Purchased = "purchased",
  Stale = "stale",
  Refunded = "refunded",
  Recalled = "recalled",
  Removed = "removed",
  Exchanged = "exchanged",
}

export const RefundableTicketStates = [TicketState.Purchased, TicketState.Refunded];

export type SupabaseTicket = {
  id: string;
  firebase_id: string;
  created_at: string;
  updated_at: string;
  user_id: string;
  user: users;
  event_id: string;
  event: Event;
  ticket_type: string;
  state: TicketState;
  quantity: number;
  entries: number;
  promotion_code?: string | null;
  coupon_code?: string | null;
  shared_from_firebase_ticket_id?: string;
  discount: number;
  price: number;
  added_to_cart_at?: string;
  checkout_started_at?: string;
  purchased_at?: string;
  admitted_at?: string;
  stripe_payment_intent_id?: string;
  guest_email?: string;
  guest_first_name?: string;
  guest_last_name?: string;
  referral_source?: string;
};

export type Ticket = tickets & {
  user: users | null;
  event: events & {
    images: event_images[];
    ticket_types: event_ticket_types[];
    /**
     * @deprecated The method should not be used, use venue instead
     */
    venues?: event_spaces[];
    event_space: event_spaces;
  };
};

export const ticketStateColorMapping: Record<TicketState, DefaultMantineColor> = {
  in_cart: "blue",
  purchased: "lime",
  stale: "gray",
  refunded: "indigo",
  recalled: "yellow",
  removed: "red",
  exchanged: "teal",
};
