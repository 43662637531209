import(/* webpackMode: "eager" */ "/vercel/path0/apps/box-office/src/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/auth.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InvalidateTrpcOnAuthStateChange"] */ "/vercel/path0/apps/box-office/src/lib/providers/auth/invalidate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/emotion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/firebase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/messages.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/posthog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/swr.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/vercel/path0/apps/box-office/src/trpc/react.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+carousel@7.16.0_@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types+reac_rssdyecfdxwbytjpkgokh5coqu/node_modules/@mantine/carousel/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+charts@7.16.0_@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types+react@_oux5sf3f5yioscal7wofwzbm6u/node_modules/@mantine/charts/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Center"] */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types+react@19.0.7_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/@mantine/core/esm/components/Center/Center.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Loader"] */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types+react@19.0.7_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/@mantine/core/esm/components/Loader/Loader.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types+react@19.0.7_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types+react@19.0.7_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+dates@7.16.0_@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types+react@1_ug44zya7dzceyfnwo65l6zalaa/node_modules/@mantine/dates/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+dropzone@7.16.0_@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types+reac_z5u63gb5yuzlwu6hm26zjuaqfu/node_modules/@mantine/dropzone/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+notifications@7.16.0_@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types_ivx6ckcvfeugz4zym5c4jlrzyy/node_modules/@mantine/notifications/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+nprogress@7.16.0_@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types+rea_7h6nupbxwl2pa66xg2becseosq/node_modules/@mantine/nprogress/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+spotlight@7.16.0_@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types+rea_5666ungeix6siqoeihwk7txare/node_modules/@mantine/spotlight/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+tiptap@7.16.0_@mantine+core@7.16.0_@mantine+hooks@7.16.0_react@19.0.0__@types+react@_omkedlebeylyn7yqzgidklcxwq/node_modules/@mantine/tiptap/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.4.1_next@15.1.5_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+t_hzgbqyfjbrapjharg75urnho3e/node_modules/@vercel/analytics/dist/react/index.mjs");
